@import '../../../scss/variables';

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 10px;
  gap: 24px;
}

.heading {
  &_h1 {
    font-size: 32px;
    font-weight: bold;
  }

  &_h2 {
    font-size: 24px;
    font-weight: bold;
  }

  &_h3 {
    font-size: 19px;
    font-weight: bold;
  }

  &_h4 {
    font-size: 16px;
    font-weight: bold;
  }

  &_h5 {
    font-size: 14px;
    font-weight: bold;
  }

  &_h6 {
    font-size: 11px;
    font-weight: bold;
  }
}

.form_input,
.form_checkbox,
.form_radio,
.form_input_number,
.form_input_date,
.form_text_area,
.form_select {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 6px;

  .label {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 6px;

    label {
      max-width: calc(100% - 40px);
    }

    .required {
      color: red;
    }

    .description {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background: rgba(0, 0, 0, 0.9);
      color: #FFF;
      width: 16px;
      height: 16px;
      font-size: 12px;

      .info_text {
        display: none;
        position: absolute;
        text-align: center;
        width: 230px;
        bottom: calc(100% + 10px);
        left: 0;
        background: #000;
        color: #FFF;
        font-size: 12px;
        padding: 10px 5px;
        border-radius: 5px 5px 5px 0;

        &:before {
          content: "";
          position: absolute;
          bottom: -5px;
          left: 2px;
          border: 5px solid #000;
          transform: rotate(45deg);
        }
      }

      &:hover {
        cursor: pointer;

        .info_text {
          display: block;
        }
      }
    }
  }

  input[type="radio"],
  input[type="checkbox"],
  input[type="date"] {
    &:hover {
      cursor: pointer;
      outline: none;
    }
  }

  input[type="date"] {
    width: auto;
    padding: 9px !important;

    &:hover,
    &::-webkit-calendar-picker-indicator {
      color: #303030;
      cursor: pointer;
      outline: none;
    }
  }

  input[type="file"] {
    &,
    &::file-selector-button {
      cursor: pointer;
    }
  }

  input:not([type="file"]),
  textarea {
    padding: 10px;
    border-radius: 5px;
    font-size: 14px;
    line-height: 16px;
    border: 1px solid #ced4da;
    color: #303030;

    &::placeholder{
      color: #ACACAC;
    }
  }
}

.form_input_number,
.form_input_date {
  input[type="date"] {
    -moz-appearance: none; /* For Firefox */
    -webkit-appearance: none; /* For Chrome and Safari */
    appearance: none;
  }
}

.form_text_area {
  textarea {
    resize: none;
    width: 100%;
    padding: 10px;
    font-family: $body-font-family;
    font-size: 16px;
  }
}

.form_button {
  width: fit-content;
  padding: 9px 12px;
  border-radius: 4px;
  min-width: 188px;
  line-height: 20px;

  &:global(.btn-danger) {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
  }

  &:global(.btn-info) {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
  }

  &:global(.btn-primary) {
    border-color: #ffb300;
    background: #ffb300;
    color: #fff;
    fill: #fff;
  }

  &:global(.btn-success) {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
  }

  &:global(.btn-warning) {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
  }
}

.form_checkbox,
.form_radio {
  .options {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    .option {
      display: flex;
      align-items: center;
      gap: 4px;

      .other_input {
        height: 38px;
      }

      label {
        font-size: 14px;
        margin: 0;
        cursor: pointer;
        color: #303030;
      }

      .toggle_border {
        position: relative;
        display: flex;
        width: 48px;
        height: 24px;
        background: #e6e6e6;
        border: 1px solid #ccc;
        border-radius: 4px;
        padding: 2px;

        .toggle {
          width: 100%;
          display: flex;
          position: relative;
          background: #e6e6e6;
          border: 1px solid #ccc;
          border-radius: 4px;
          transition: .5s;

          input {
            opacity: 0;
            width: 40px;
            height: 16px;
            cursor: pointer;
          }

          .check {
            position: absolute !important;
            width: 50%;
            height: 100%;
            border-radius: 3px;
            background: linear-gradient(to bottom, #fff 0%, #ccc 100%);
            border: 1px solid #999;
            transform: translate(0);
            transition: .5s;
            pointer-events: none;
          }

          &.checked {
            background: #6fc665;
            transition: .5s;

            .check {
              transform: translate(100%);
            }
          }
        }
      }

      input[type="checkbox"] {
        cursor: pointer;
      }
    }
  }
}

.form_select {
  .select {
    position: relative;
    padding: 10px;
    height: 38px;
    border: 1px solid #303030;

    .select_button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      font-size: 14px;
      line-height: 18px;
      color: #303030;
    }

    .select_options {
      visibility: hidden;
      opacity: 0;
    }

    svg {
      width: 18px;
      height: 18px;
      transition: .5s;
    }

    &.open {
      .select_options {
        visibility: visible;
        opacity: 1;
        width: calc(100% + 2px);
        position: absolute;
        top: calc(100% + 1px);
        left: -1px;
        background: #FFF;
        border: 1px solid #0090E1;
        border-radius: 5px;
        overflow: hidden;
        z-index: 1;
        transition: .5s;
      }

      .option {
        background: #FFF;
        font-size: 12px;
        font-weight: 400;
        color: #303030;
        padding: 4px 8px;
        cursor: pointer;

        &.selected {
          color: #FFF;
          background: #0090E1;
        }

        &:hover {
          color: #FFF;
          background: #0090E1B3;
        }
      }

      svg {
        transform: rotate(180deg);
      }
    }

    &.warning {
      .select_options {
        border: 1px solid red !important;
      }
    }
  }
}

.warning {
  border: 1px solid red !important;
}

@media screen and (max-width: 425px) {
  .form_input,
  .form_checkbox,
  .form_radio,
  .form_input_number,
  .form_input_date,
  .form_text_area,
  .form_select {
    position: relative;

    .label {
      .description {
        position: unset;

        .info_text {
          left: calc(50% - 115px);
          border-radius: 5px;

          &:before {
            display: none;
          }
        }
      }
    }
  }
}