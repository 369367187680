@import "../../../scss/variables";

.home-product-container {
  margin: 47px auto !important;
  @media (max-width: 600px) {
    margin: 10px auto !important;
  }

}

.block-products__body {
  width: 100%;
  display: flex;

    .block-products__list {
      display: grid;
      grid-template-columns: repeat(4,calc((100% - 60px) / 4));
      width: 100%;
      gap: 20px;

    @media (max-width: 991px) {
      grid-template-columns: repeat(3, calc((100% - 50px) / 3));
    }

    @media (max-width: 600px) {
      grid-template-columns: repeat(2, calc((100% - 25px) / 2));
    }

    @media (max-width: 425px) {
      grid-template-columns: repeat(2, calc((100% - 10px) / 2));
      gap: 10px;
    }
  }

  .product-card-parent {
    margin-bottom: 20px;
  }
}

.product-card {
  position: relative;
  flex-direction: column;
  background: $product-card-bg;
  width: 100%;
  flex-shrink: 0;

  .product-card__image {
    &:hover {
      .product-image__body {
        .item_overlay {
          background-color: gray;
        }
      }
    }

    .product-image__body {
      display: block;
      position: relative;
      width: 100%;

      &:hover {
        .img_btn_wrapper {
          opacity: 1;
        }
      }

      .item_overlay {
        height: 100%;
        width: 100%;
        background-color: transparent;
        opacity: 0.2;
        display: block;
        z-index: 1;
        position: absolute;
        margin: 0;
        transition: background-color 0.2s;
      }

      .img_btn_wrapper {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        position: absolute;
        z-index: 3;
        height: 100%;
        width: inherit;
        opacity: 0;
        transition: opacity 0.2s;
        cursor: pointer;

        .product-card__addToCart {
          border: none;
          width: 227px;
          height: 41px;
          margin-bottom: 20px;
          font-size: 16px;
        }
      }


      .img_wrapper {
        width: 100%;
        object-fit: contain;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        img {
          width: 100% !important;
          height: 100% !important;
          aspect-ratio: .8125;
          object-fit: cover;
          @media only screen and (max-width: 768px) {
            width: 100%;
            height: 100%;
          }
          @media only screen and ((max-width: 430px) and (min-width: 420px)) {
            width: 193px;
            height: 193px;
          }
        }
      }
    }
  }

  .product-card__info {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-top: 4px;

    .product-card__name {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      height: auto;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      a {
        color: $body-font-color;
        transition: 0.15s;
      }

      a:hover {
        color: $main-theme-color-light;
      }
    }

    .product-card-description {
      font-size: 14px;
      height: 25px;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-top: 3px;
    }
  }

  .product-card__actions {
      .product-card__availability {
        display: none;
      }

      .prices {
        gap: 8px;
        color: #000;
        display: flex;
        font-size: 18px;
        font-weight: 500;
        align-items: center;

        @media(max-width: 425px) {
          gap: unset;
          justify-content: space-between;
        }

        .currency__value {
          gap: 2px;
          display: flex;
        }

        .new_price {
          color: $price-new-color;
        }

        .old_price {
          font-size: 14px;
          color: $price-old-color;
          font-weight: $font-weight-normal;
          text-decoration: line-through;
        }
      }

    .product-card__addToCart {
      height: max-content;
      width: 100%;
      font-size: 14px;
      margin-top: 5px;
      background: $main-theme-color-light;
      border: none;

      &.hide-for-desktop {
        display: none;
      }
    }
  }
}

.product-card__buttons {
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 4;
  display: inline-block;
  float: right;
  cursor: pointer;

  div {
    background: unset !important;

    .wishlist-circle:focus {
      outline: none !important
    }

    .wishlist-icon-wrapper {
      svg {
        width: 32px;
        height: 32px;
        position: unset;
        transform: unset;

        @media(max-width: 525px) {
          width: 20px;
          height: 20px;
        }

        path {
          stroke: #707070;
        }
      }

      .wish-icon {
        .inner-wishlist {
          path {
            stroke: none;
          }
        }
      }
    }

    .wishlist-circle-clicked {
      path {
        fill: $main-theme-color-dark;
      }
    }

    .inner-wishlist {
      width: 16px;
      height: 16px;

      path {
        fill: $main-theme-color-dark !important;
        stroke: $main-theme-color-dark !important;

        > &:focus {
          outline: none;
        }
      }

      > &:focus {
        outline: none;
      }
    }
  }

  :hover {
    .wishlist-icon-wrapper {
      svg {
        path {
          stroke: #121212 !important;
        }
      }
    }
  }
}

.wish-icon:hover path {
  stroke: black;
}

@media screen and (max-width: 991px) {
  .home-product-container {
    max-width: 900px;
  }

  .block-products__body {
    display: block;
  }

  .product-card {
    .product-card__image {
      .product-image__body {
        .item_overlay {
          display: none;
        }

        .img_btn_wrapper {
          z-index: unset;

          .product-card__addToCart {
            display: none;
          }
        }
      }
    }

    .product-card__actions {
      .product-card__availability-mobile {
        width: 100%;

        .product-card__availability {
          display: none;
        }
      }

      .product-card__addToCart {
        &.hide-for-desktop {
          display: block;
        }
      }
    }
  }

  .product-card {
    .product-card__image {
      .product-image__body {
        .item_overlay {
          display: none;
        }

        .img_btn_wrapper {
          z-index: unset;

          .product-card__addToCart {
            display: none;
          }
        }
      }
    }

    .product-card__actions {
      .product-card__availability-mobile {
        .product-card__availability {
          display: none;
        }
      }

      .product-card__addToCart {
        &.hide-for-desktop {
          height: 40px;
          display: block;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .product-card {
    .product-card__actions {
      .product-card__availability-mobile {
        .prices {

          .old_price {
            //font-size: 13px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .product-card {
    .product-card__actions {
      .product-card__availability-mobile {
        .prices {
          font-size: 18px !important;
        }
      }
    }
  }
}

@media screen and (max-width: 425px) {
  .product-card {
    .product-card__actions {
      .product-card__availability-mobile {
        .prices {
          font-size: 16px !important;
        }
      }
    }
  }
}